import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import Amplify from 'aws-amplify';
import { registerLocaleData } from '@angular/common';
import {COGNITO, S3_BUCKET} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AlphaTimeModule} from '../components/componentes.moduel';
import {EmployeeComponent} from './employee/employee.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppHttpInterceptors} from './app-http-interceptors';
import {ProfileComponent} from './profile/profile.component';
import {InputMaskModule} from 'primeng/inputmask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HistoryComponent} from './history/history.component';
import {CalendarModule} from 'primeng/calendar';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DropdownModule} from 'primeng/dropdown';
import {EmployeeCreateComponent} from './employee-create/employee-create.component';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {SkeletonModule} from 'primeng/skeleton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {InputTextModule} from 'primeng/inputtext';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DialogModule} from 'primeng/dialog';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PasswordModule} from 'primeng/password';
import {DataViewModule} from 'primeng/dataview';
import {ConfigureEmployeeComponent} from './configure-employee/configure-employee.component';
import {EmployeeAboutComponent} from './employee-about/employee-about.component';
import {EmployeeConfigurationComponent} from './employee-configuration/employee-configuration.component';
import {EmployeeJustificationsComponent} from './employee-justifications/employee-justifications.component';
import {EmployeeVacationsComponent} from './employee-vacations/employee-vacations.component';
import {PhonePipe} from './shared/phone-pipe';
import {CommonModule} from '@angular/common';
import {EmployeeVacationsTableComponent} from './employee-vacations-table/employee-vacations-table.component';
import {EmployeeJustificationTableComponent} from './employee-justification-table/employee-justification-table.component';
import {EmployeeStatusPipe} from './shared/employee-status.pipe';
import {CompTimeComponent} from './comp-time/comp-time.component';
import {MapComponent} from './map/map.component';
import {NumberDirectiveDirective} from './shared/number-directive.directive';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {CompanyComponent} from './company/company.component';
import {CompanyUpdateComponent} from './company-update/company-update.component';
import {CompanyPositionComponent} from './company-position/company-position.component';
import {CompanyPositionCreateComponent} from './company-position-create/company-position-create.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {PasswordRecoveryComponent} from './password-recovery/password-recovery.component';
import {NewPasswordComponent} from './new-password/new-password.component';
import {NoticesComponent} from './notices/notices.component';
import {RootStoreModule} from '../stores/root-store.module';
import {LayoutModule} from '../layouts/layout.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ForgotPasswordComponent} from './forgot-password/forgot-password..component';
import { CompleteNewPasswordComponent } from './complete-new-password/complete-new-password.component';
import { EditingPersonalDataComponent } from './editing-personal-data/editing-personal-data.component';
import { EditingPersonalBankdataComponent } from './editing-personal-bankdata/editing-personal-bankdata.component';
import { TabViewModule } from 'primeng/tabview';
import { WorkingDayComponent } from './working-day/working-day.component';
import { NoticePriorityPipe } from './shared/notice-priority.pipe';
import { SignatureComponent } from './signature/signature.component';
import { EditingWorkplaceComponent } from './editing-workplace/editing-workplace.component';
import { DepartmentCreateComponent } from './department-create/department-create.component';
import {TreeSelectModule} from 'primeng/treeselect';
import { CompanyNewComponent } from './company-new/company-new.component';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {FileUploadModule} from 'primeng/fileupload';
import {CheckboxModule} from 'primeng/checkbox';
import {PaginatorModule} from 'primeng/paginator';
import {StepsModule} from 'primeng/steps';
import { TimeManagementComponent } from './time-management/time-management.component';
import { EmployeeRegisterTimeComponent } from './employee-register-time/employee-register-time.component';

import localePT from '@angular/common/locales/pt';
import { TextTruncatePipe } from './shared/text-truncate.pipe';
import { ContactComponent } from './contact/contact.component';
import { CheckProductTypeDirective } from './shared/check-product-type.directive';
import { HiddeIsFreePlanDirective } from './shared/hidde-is-free-plan.directive';
import { EmployeeLeaveComponent } from './employee-leave/employee-leave.component';
import { EmployeeLeaveTableComponent } from './employee-leave-table/employee-leave-table.component';
import { AbsenceTypeTextConvertPipe } from './shared/absence-type-text-convert.pipe';
import { FormCreatorComponent } from './form-creator/form-creator.component';
import { FormsListComponent } from './forms-list/forms-list.component';
import { FormActivateComponent } from './form-activate/form-activate.component';
import {DateRangePipe} from './shared/date-range-pipe';

registerLocaleData(localePT);

Amplify.configure({
    Auth: {
        identityPoolId: COGNITO.identityPoolId,
        region: COGNITO.region,
        userPoolId: COGNITO.userPoolId,
        userPoolWebClientId: COGNITO.userPoolWebClientId
    },
    Storage: {
        AWSS3: {
            bucket: S3_BUCKET.name,
            region: S3_BUCKET.region,
        }
    }
});

Amplify.Logger.LOG_LEVEL = 'DEBUG';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const httpInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptors, multi: true},
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        DashboardComponent,
        EmployeeComponent,
        ProfileComponent,
        HistoryComponent,
        EmployeeCreateComponent,
        ConfigureEmployeeComponent,
        EmployeeAboutComponent,
        EmployeeConfigurationComponent,
        EmployeeJustificationsComponent,
        EmployeeVacationsComponent,
        PhonePipe,
        EmployeeVacationsTableComponent,
        EmployeeJustificationTableComponent,
        EmployeeStatusPipe,
        CompTimeComponent,
        MapComponent,
        NumberDirectiveDirective,
        CheckProductTypeDirective,
        HiddeIsFreePlanDirective,
        CompanyComponent,
        CompanyUpdateComponent,
        CompanyPositionComponent,
        CompanyPositionCreateComponent,
        ChangePasswordComponent,
        PasswordRecoveryComponent,
        NewPasswordComponent,
        NoticesComponent,
        CompleteNewPasswordComponent,
        EditingPersonalDataComponent,
        EditingPersonalBankdataComponent,
        WorkingDayComponent,
        NoticePriorityPipe,
        SignatureComponent,
        EditingWorkplaceComponent,
        DepartmentCreateComponent,
        CompanyNewComponent,
        TimeManagementComponent,
        EmployeeRegisterTimeComponent,
        TextTruncatePipe,
        ContactComponent,
        EmployeeLeaveComponent,
        EmployeeLeaveTableComponent,
        AbsenceTypeTextConvertPipe,
        FormCreatorComponent,
        FormsListComponent,
        FormActivateComponent,
        DateRangePipe,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AppRoutingModule,
        AlphaTimeModule,
        LayoutModule,
        InputMaskModule,
        CalendarModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        ToastModule,
        TableModule,
        TooltipModule,
        PanelModule,
        SkeletonModule,
        DividerModule,
        InputSwitchModule,
        PasswordModule,
        DataViewModule,
        ConfirmDialogModule,
        DialogModule,
        InputTextModule,
        ToggleButtonModule,
        RadioButtonModule,
        CardModule,
        RxReactiveFormsModule,
        DynamicDialogModule,
        RootStoreModule,
        ProgressSpinnerModule,
        TabViewModule,
        TreeSelectModule,
        AvatarModule,
        AvatarGroupModule,
        FileUploadModule,
        CheckboxModule,
        PaginatorModule,
        StepsModule
    ],
    providers: [httpInterceptorProviders, { provide: LOCALE_ID, useValue: 'pt-br' }],
    bootstrap: [AppComponent],
    exports: [PhonePipe],
})
export class AppModule {
}
