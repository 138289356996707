import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {AppStorageService} from '../../services/app-storage.service';
import {ContractService} from '../../services/contract.service';
import {Table} from 'primeng/table';
import {CompanyService} from 'src/services/company.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Store} from '@ngrx/store';
import * as EmployeeAction from '../../stores/actions/employee.action';
import {getEmployees} from '../../stores/selectors/employee.selector';
import {Employee} from 'src/model/employee';
import {fireEmployee, reemployEmployee} from '../../stores/actions/meta.action';
import {Company} from '../../model/company';
import {EmployeeService} from 'src/services/employee.service';
import {EmployeeState} from '../../stores/states/employee.state';
import {DOCUMENT} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Department} from 'src/model/department';
import {DepartmentService} from 'src/services/department.service';
import {EventEmitterService} from '../../services/event-emitter.service';
import ChannelNotification from '../../model/channel-notifications';
import {isCPFValid, isEmailValid} from '../app.validators';

interface SelectComponentForm {
    branchId: string;
    companyId: string;
    companyPositionId: string;
    departmentId: string;
    path: string;
}

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class EmployeeComponent implements OnInit, OnDestroy {

    @ViewChild('dt') table?: Table;

    public employees: any = [] = [];
    public departments: Department[] = [];
    public employee: Employee | undefined;
    public formGroup: FormGroup;
    public selectComponentForm!: SelectComponentForm;
    public selectFile!: null;
    public selectedEmployee: any[] = [];
    public isFormValid = false;
    public isManager = false;
    public showWarning = false;
    public showCreatedEmployeeModal = false;
    public showUploadModal = false;
    public loadingEmployees = false;
    public changeViewValue = false;
    public thereIsBranch = false;
    public showSelectCompanies = false;
    public showFileInput = false;
    public showTooltip = false;
    public companyIdTemp = '';
    public picture = './assets/icon/Avatar/user.png';
    public hasCSVError = false;
    public csvErrorMessage = '';
    public processingDialogFile = false;
    public processingDialogMsg = '';
    public processingDialogPercent = '25%';
    public fileName = '';


    constructor(
        private store: Store<EmployeeState>,
        private appStorageService: AppStorageService,
        private companyService: CompanyService,
        public employeeService: EmployeeService,
        private contractService: ContractService,
        private departmentService: DepartmentService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        @Inject(DOCUMENT) private document: Document,
        private router: Router) {
        this.formGroup = this.formBuilder.group({
            companyId: ['', Validators.required],
            departmentId: ['', Validators.required],
            companyPositionId: ['', Validators.required],
            path: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        this.changeView(false);
    }

    async ngOnInit(): Promise<void> {
        this.changeView(false);
        await this.initializeData();
        this.activatedRoute.params.subscribe(params => {
            if (params.type === 'grid') {
                this.changeView(true);
            } else {
                this.changeView(false);
            }
        });
        EventEmitterService.get(EventEmitterService.EMPLOYEE_CHANNEL).subscribe((msg: any) => {
            const payload = JSON.parse(msg.data);
            if (payload.type === ChannelNotification.END_EMPLOYEE_CSV_IMPORT_TYPE){
                this.initializeData();
                this.processingDialogMsg = 'Concluído';
                this.processingDialogPercent = '100%';
                this.processingDialogFile = false;
            }
        });
    }

    public async initializeData(): Promise<void> {

        this.employee = await this.appStorageService.getEmployee();
        this.isManager = this.employee.role === 'MANAGER' || this.employee.role === 'DEPARTAMENT_MANAGER';

        this.loadingEmployees = true;
        this.store.dispatch(EmployeeAction.loadEmployeeRequestAction({id: this.employee.companyId as string}));
        this.store.select(getEmployees).subscribe((employees: any) => {
            const result: Employee[] = [];
            employees.map((obj: any) => {
                if (obj.contract){
                    result.push(obj);
                    this.employees?.push({
                        name: obj.name,
                        email: obj.email,
                        phone: obj.phone,
                        registryId: obj.registryId,
                        status: obj.contract.status
                    });
                }
            });
            this.loadingEmployees = false;
            this.employees = result;
        });
    }

    async goToConfigureEmployee(data: any): Promise<void> {
        const contract = await this.contractService.getContractsByEmployee(data.id).toPromise();
        const company: any = await this.companyService.get(data.companyId).toPromise();

        const navigationExtras: NavigationExtras = {
            state: {
                employee: data,
                contract: contract?.data[0],
                company: company.data
            }
        };

        await this.router.navigate(['/configure-employee'], navigationExtras);
    }

    public onBtnCreateEmployee(): void {
        this.showCreatedEmployeeModal = true;
    }

    public onShowUploadModal(): void {
        this.showSelectCompanies = true;
        this.showFileInput = true;
        this.showCreatedEmployeeModal = false;
        this.showUploadModal = true;
    }

    public onCloseUploadModal(): void {
        this.showSelectCompanies = false;
        this.showFileInput = false;
        this.showUploadModal = false;
    }

    public onFileSelected(event: any): void {
        if (event && event.file) {
            this.selectFile = event.file;
            this.fileName = event.fileName;
        } else {
            this.hasCSVError = false;
            this.selectFile = null;
            this.fileName = '';
        }
    }

    public async onNewEmployee(): Promise<void> {
        this.showCreatedEmployeeModal = false;
        const getCompany: any = await this.appStorageService.getCompany();
        const company: any = await this.companyService.get(getCompany.id).toPromise();

        if (company && company.status === Company.EXPIRED_STATUS) {
            await this.router.navigate(['/login']);
        } else {
            if (company.data.allowNewUsers) {
                await this.router.navigateByUrl('/employee-create');
            } else {
                this.showWarning = true;
                return;
            }
        }
    }

    public async confirmFireEmployee(employee: any): Promise<void> {
        const contract: any = await this.contractService.getContractsByEmployee(employee.id).toPromise();

        this.confirmationService.confirm({
            message: `Você tem certeza que deseja desligar esse funcionário?`,
            header: 'Desligar funcionário',
            accept: async () => {
                await this.fireEmployee(contract.data[0]);
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Usuário não foi desligado!'
                });
            }
        });
    }

    public async confirmReemployEmployee(employee: any): Promise<void> {
        const contract: any = await this.contractService.getContractsByEmployee(employee.id).toPromise();

        this.confirmationService.confirm({
            message: `Você tem certeza que deseja readmitir esse funcionário?`,
            header: 'Readmitir funcionário',
            accept: () => {
                this.reemployEmployee(contract.data[0]);
            },
            reject: () => {
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Não confirmado',
                    detail: 'Usuário não recontratado!'
                });
            }
        });
    }

    public async fireEmployee(contract: any): Promise<void> {
        try {
            await this.contractService.fireEmployeeContract(contract.id!).toPromise();
            this.messageService.add({
                severity: 'success',
                summary: 'Sucesso',
                detail: 'Funcionário Desligado com Sucesso!'
            });

            this.store.dispatch(fireEmployee());
            await this.initializeData();
        } catch (error) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Desculpe',
                detail: 'Algum problema ocorreu, por favor entre em contato com o suporte!'
            });
        }
    }

    public async reemployEmployee(contract: any): Promise<void> {
        try {
            await this.contractService.reemployEmployeeContract(contract.id!).toPromise();
            this.messageService.add({
                severity: 'success',
                summary: 'Sucesso',
                detail: 'Funcionário recontratado com Sucesso!'
            });
            this.store.dispatch(reemployEmployee());
            await this.initializeData();
        } catch (error) {
            this.messageService.add({
                severity: 'warn',
                summary: 'Desculpe',
                detail: 'Algum problema ocorreu, por favor entre em contato com o suporte!'
            });
        }
    }

    private changeView(value: boolean): void {
        if (value) {
            this.document.documentElement.style.setProperty('--card-display', 'flex');
        } else {
            this.document.documentElement.style.setProperty('--card-display', 'contents');
        }
        this.changeViewValue = value;
    }

    async selectValues(value: any): Promise<void> {
        this.selectComponentForm = value;
        const {companyId, branchId, departmentId, companyPositionId, path} = value;

        if (companyId !== '' && branchId !== '') {
            this.companyIdTemp = branchId;
        } else {
            this.companyIdTemp = companyId;
        }

        this.formGroup.get('companyId')?.setValue(this.companyIdTemp);
        this.formGroup.get('departmentId')?.setValue(departmentId);
        this.formGroup.get('companyPositionId')?.setValue(companyPositionId);
        this.formGroup.get('path')?.setValue(path);
    }

    async sendUploadFile(): Promise<void> {
        if (this.selectFile) {
            this.processingDialogFile = true;
            this.processingDialogMsg = 'Validando Arquivo...';
            this.validateCSVFile(this.selectFile).then(async (result) => {
                if (result.valid) {
                    this.processingDialogMsg = 'Processando...';
                    this.processingDialogPercent = '50%';
                    this.hasCSVError = false;
                    const {
                        companyId,
                        departmentId,
                        companyPositionId,
                        path
                    } = this.formGroup.value;
                    // @ts-ignore
                    const employeeId = this.employee.id;
                    const response = await this.employeeService.getUploadUrl({
                        employeeId,
                        companyId,
                        departmentId,
                        path,
                        companyPositionId
                    }).toPromise();

                    if (response.success){
                      await this.employeeService.uploadCSVFile(response.data.url, this.selectFile).toPromise();
                    }
                    this.onCloseUploadModal();
                } else {
                    this.hasCSVError = true;
                    this.csvErrorMessage = result.error;
                    this.processingDialogFile = false;
                }
            });
        }
    }

    async onFormStatusChange(status: boolean): Promise<void> {
        this.isFormValid = status;
        await this.getDepartments(this.companyIdTemp);
    }

    async getDepartments(companyId: string): Promise<void> {
        this.departments = [];
        try {
            this.departmentService.list(companyId).subscribe(department => {
                const result: Department[] = [];
                department?.data?.map((department: Department) => {
                    if (department.status === 'ACTIVE') {
                        result.push(department);
                    }
                });

                this.departments = result;
            });
        } catch (error: any) {
        }
    }

    private async validateCSVFile(csvFile: File): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const duplicates = (files: any[], key: string) => {
                    const seen = new Set();
                    for (const f of files) {
                        if (seen.has(f[key])) {
                            return [f[key]];
                        } else {
                            seen.add(f[key]);
                        }
                    }
                    return [];
                };
                const reader = new FileReader();
                reader.readAsText(csvFile, 'UTF-8');
                const dataShouldBeValid: any[] = [];

                reader.onload = (event: any) => {
                    const cvsData = event.target.result;
                    const rowData = cvsData.split('\n');

                    rowData.forEach((line: string) => {
                        if (line.trim().length > 0){
                            const data: string[] = line.trim().split(',');
                            // if (data.length === 0) {
                            //     resolve({valid: false, error: 'Existem linhas no arquivo em branco, por favor, revise-o e envie novamente.'});
                            //     return;
                            // }

                            if (data.some(item => item.replace(/\s/g, '').length === 0)){
                                resolve({
                                    valid: false,
                                    error: 'Existem colunas com valores em branco, por favor, revise-o e envie novamente.'
                                });
                                return;
                            }

                            if (data.length < 11) {
                                resolve({
                                    valid: false,
                                    error: 'Existem linhas no arquivo faltando informações, por favor, revise-o e envie novamente.'
                                });
                                return;
                            }

                            if (isCPFValid(data[1].replace(/[^a-zA-Z0-9 ]/g, '')) ){
                                resolve({
                                    valid: false,
                                    error: `O CPF ${data[1]} é inválido.`
                                });
                                return;
                            }

                            if (isEmailValid(data[2].replace(/[^a-zA-Z0-9 ]/g, '')) ){
                                resolve({
                                    valid: false,
                                    error: `O E-mail ${data[2]} é inválido.`
                                });
                                return;
                            }

                            // if (!isNaN(parseFloat(data[5].replace(/[^a-zA-Z0-9 ]/g, ''))) &&
                            //     isFinite(Number(data[5].replace(/[^a-zA-Z0-9 ]/g, '')))){
                            //     resolve({
                            //         valid: false,
                            //         error: `Número do endereço é inválido, é aceito apenas números, ${data[5]}.`
                            //     });
                            //     return;
                            // }

                            if (data[3].replace(/[^a-zA-Z0-9 ]/g, '')
                                    .replace(/\s/g, '').length > 11
                                && !isNaN(parseFloat(data[3].replace(/[^a-zA-Z0-9 ]/g, ''))) &&
                                     isFinite(Number(data[3].replace(/[^a-zA-Z0-9 ]/g, '')))){
                                resolve({
                                    valid: false,
                                    error: `Número de Telefone ${data[3]} está com formato errado.`
                                });
                                return;
                            }

                            if (this.employees.filter(
                                (employee: Employee) => employee.email === data[2]).length > 0) {
                                resolve({
                                    valid: false,
                                    error: `Email ${data[2]} já está em uso no sistema, por favor, revise-o e envie novamente.`
                                });
                                return;
                            }

                            if (this.employees.filter(
                                (employee: Employee) => employee.registryId === data[1]).length > 0) {
                                resolve({
                                    valid: false,
                                    error: `CPF ${data[1]} já está em uso no sistema, por favor, revise-o e envie novamente.`
                                });
                                return;
                            }

                            dataShouldBeValid.push({cpf: data[1], email: data[2]});
                        }
                    });

                    for (const d of duplicates(dataShouldBeValid, 'cpf')) {
                        resolve({valid: false, error: `CPF ${d} está duplicado no arquivo, por favor, revise-o e envie novamente.`});
                        return;
                    }

                    for (const d of duplicates(dataShouldBeValid, 'email')) {
                        resolve({valid: false, error: `Email ${d} está duplicado no arquivo, por favor, revise-o e envie novamente.`});
                        return;
                    }
                    resolve({valid: true});
                };
                reader.readAsText(csvFile);
            } catch (error: any) {
                reject(error);
            }
        });
    }

}
