import {ChangePasswordComponent} from './change-password/change-password.component';
import {CompanyPositionComponent} from './company-position/company-position.component';
import {CompanyUpdateComponent} from './company-update/company-update.component';
import {CompanyComponent} from './company/company.component';
import {CompTimeComponent} from './comp-time/comp-time.component';
import {EmployeeCreateComponent} from './employee-create/employee-create.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AppAuthGuard} from './app-auth-guard';
import {EmployeeComponent} from './employee/employee.component';
import {ProfileComponent} from './profile/profile.component';
import {HistoryComponent} from './history/history.component';
import {ConfigureEmployeeComponent} from './configure-employee/configure-employee.component';
import {EmployeeVacationsTableComponent} from './employee-vacations-table/employee-vacations-table.component';
import {EmployeeVacationsComponent} from './employee-vacations/employee-vacations.component';
import {EmployeeJustificationTableComponent} from './employee-justification-table/employee-justification-table.component';
import {LoginComponent} from './login/login.component';
import {AdminLayoutComponent} from '../layouts/admin/admin-layout.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password..component';
import {SignatureComponent} from './signature/signature.component';
import {TimeManagementComponent} from './time-management/time-management.component';
import {EmployeeRegisterTimeComponent} from './employee-register-time/employee-register-time.component';
import {ProfileGuardService} from 'src/services/profile-guard.service';
import { EmployeeLeaveComponent } from './employee-leave/employee-leave.component';
import { EmployeeLeaveTableComponent } from './employee-leave-table/employee-leave-table.component';
import {FormCreatorComponent} from './form-creator/form-creator.component';
import {FormsListComponent} from './forms-list/forms-list.component';

const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
            },
            {
                path: 'employee/:type',
                component: EmployeeComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'company',
                component: CompanyComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'company-update',
                component: CompanyUpdateComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'company-position',
                component: CompanyPositionComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'comp-time',
                component: CompTimeComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'time-management',
                component: TimeManagementComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee-create',
                component: EmployeeCreateComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee-leave-table',
                component: EmployeeLeaveTableComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee/:id',
                component: EmployeeCreateComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'configure-employee',
                component: ConfigureEmployeeComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'profile',
                component: ProfileComponent,
            },
            {
                path: 'history',
                component: HistoryComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee-vacations-table',
                component: EmployeeVacationsTableComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee-vacations',
                component: EmployeeVacationsComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'employee-justification',
                component: EmployeeJustificationTableComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'signature',
                component: SignatureComponent,
                canActivate: [ProfileGuardService]
            },
            {
                path: 'register-time',
                component: EmployeeRegisterTimeComponent,
            },
            {
                path: 'form-creator',
                component: FormCreatorComponent,
            },
            {
                path: 'forms-list',
                component: FormsListComponent,
            },
           // {path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
        ],
        canActivate: [AppAuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'checkout',
        component: SignatureComponent,
    },
    {
        path: 'password-recovery',
        component: ForgotPasswordComponent
    },
    {path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
