import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_URL} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormGeneratorService {

    // save-survey: post: /surveys
    // update-survey: put: /surveys/{id}
    // delete-survey: delete: /surveys/{id}/company
    // list-survey: get: /surveys/company/{companyId}
    // get-active-survey: get: /surveys/department/{departmentId}

    constructor(
        private httpClient: HttpClient
    ) { }

    /**
     *  Form Generator get by id
     *
     */
    public getOne(formId: string): Observable<any> {
        return this.httpClient.get(``);
    }

    /**
     *  Form Generator Find All
     *
     */
    public findAll(companyId: string, paramsObj?: { name: string; startDate: string; endDate: string }): Observable<any> {
        let params = new HttpParams();

        if (paramsObj) {
            if (paramsObj.name) {
                params = params.set('name', paramsObj.name || '');
            }
            if (paramsObj.startDate) {
                params = params.set('startDate', paramsObj.startDate || '');
            }
            if (paramsObj.endDate) {
                params = params.set('endDate', paramsObj.endDate || '');
            }
        }

        return this.httpClient.get(`${SERVER_URL}/surveys/company/${companyId}`, { params });
    }

    /**
     *  Form Generator Save
     *
     */
    public save(data: any): Observable<any> {
        return this.httpClient.post(`${SERVER_URL}/surveys`, data);
    }

    /**
     *  Form Delete
     *
     */
    public delete(id: string): Observable<any> {
        return this.httpClient.delete(`${SERVER_URL}/surveys/${id}/company`);
    }

    /**
     *  Form Delete
     *
     */
    public update(id: string, data: any): Observable<any> {
        return this.httpClient.put(`${SERVER_URL}/surveys/${id}`, data);
    }

    public getSurveyByRef(refId: string): Observable<any> {
        return this.httpClient.get<any>(`${SERVER_URL}/surveys/ref/${refId}`);
    }
}
