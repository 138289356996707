import {FormGroup, Validators} from '@angular/forms';


export const EmailValidation = [Validators.required, Validators.email];

export const PasswordValidation = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(24),
];

export function RepeatPasswordValidator(group: FormGroup ): null | { passwordsNotEqual: true } {
    const password = group.controls.password.value;
    const passwordConfirmation = group.controls.confirmPassword.value;

    return password === passwordConfirmation ? null : { passwordsNotEqual: true };
}

// @ts-ignore
export function RegisterIdValidator(group: FormGroup ): null | { CNPJNotValid: true } | { CPFNotValid: true } {
    const registryId = group.controls.registryId.value;
    const value  = removeAnySpaces(removePunctuation(registryId));

    if (value.length === CPF_LENGTH){
        return isCPFValid(value) ? null : { CPFNotValid : true };
    }

    if (value.length === CNPJ_LENGTH){
        return isCNPJValid(value) ? null : { CNPJNotValid : true };
    }
}

export function CustomEmailValidator(group: FormGroup): boolean {
    const email = group.controls.email.value;
    const value  = removeAnySpaces(removePunctuation(email));
    return isEmailValid(value);
}

export function isEmailValid(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

// @ts-ignore
export function NameLengthValidator(group: FormGroup ): null | { isNameValid: true } {
    const name = group.controls.name.value;

    const strSplit = name.split(' ');
    let longestWord = 0;

    for (const item of strSplit) {
        if (item.length > longestWord){
         longestWord = item.length;
        }
    }

    if (longestWord > 20){
        return { isNameValid : true };
    }
}

// @ts-ignore
export function CnpjValidator(group: FormGroup ): null | { CNPJNotValid: true } {
    const cnpj = group.controls.cnpj.value;
    const value  = removeAnySpaces(removePunctuation(cnpj));

    if (value.length === CNPJ_LENGTH){
        return isCNPJValid(value) ? null : { CNPJNotValid : true };
    }
}

export const CPF_LENGTH = 11;
export const CNPJ_LENGTH = 14;
export const DIVISOR = 11;
export const CPF_WEIGHTS = [[10, 9, 8, 7, 6, 5, 4, 3, 2], [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]];
export const CNPJ_WEIGHTS = [[5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2], [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]];


const removePunctuation = (value: string) => {
    return value.replace(/(~|`|!|@|#|$|%|^|&|\*|\(|\)|{|}|\[|\]|;|:|\"|'|<|,|\.|>|\?|\/|\\|\||-|_|\+|=)/g, '');
};

const removeAnySpaces = (value: string) => {
    return value.replace(/ +?/g, '');
};

export const isCPFValid = (cpf: string) => {
    const CPF = cpf.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
    const CPF_SET = new Set(CPF.split(''));
    if (CPF.length !== CPF_LENGTH || CPF_SET.size === 1) {
        return false;
    }

    const firstPart = CPF.substring(0, 9);
    const secondPart = CPF.substring(0, 10);
    const firstDigit = CPF[9];
    const secondDigit = CPF[10];

    return firstDigit === calculateFirstDigit(firstPart) && secondDigit === calculateSecondDigit(secondPart);
};

const isCNPJValid = (cnpj: string) => {
    const CNPJ = cnpj.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
    const CNPJ_SET = new Set(CNPJ.split(''));
    if (CNPJ.length !== CNPJ_LENGTH || CNPJ_SET.size === 1) {
        return false;
    }

    const firstPart = CNPJ.substring(0, 12);
    const secondPart = CNPJ.substring(0, 13);
    const firstDigit = CNPJ[12];
    const secondDigit = CNPJ[13];

    return firstDigit === calculateFirstDigit(firstPart) && secondDigit === calculateSecondDigit(secondPart);
};

const calculateFirstDigit = (value: any) => {
    return calculateDigit(value);
};

const calculateSecondDigit = (value: any) => {
    return calculateDigit(value, 2, 10);
};

const calculateDigit = (value: any, digit = 1, sizeToCheck = 9) => {
    let sum = 0;
    const weights = (value.length === sizeToCheck) ?
        CPF_WEIGHTS[digit - 1] :
        CNPJ_WEIGHTS[digit - 1];

    for (let index = 0; index < value.length; index++) {
        // tslint:disable-next-line:radix
        sum = sum + parseInt(value[index]) * weights[index];
    }
    const restDivision = sum % DIVISOR;
    if (restDivision < 2) {
        return '0';
    }

    return String(DIVISOR - restDivision);
};

