<p-toast></p-toast>
<p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000"
                 rejectButtonStyleClass="p-button-text"
                 acceptButtonStyleClass="p-button-rounded p-button-danger"
                 acceptLabel="Sim"
                 rejectLabel="Não"
                 acceptIcon="none"
                 rejectIcon="none">
</p-confirmDialog>

<p-dialog header="Redefinir Senha" [(visible)]="showResetPwdEmployeeModal" [style]="{width: '560px'}">
    <div>
            <span style="font: normal normal normal 16px/22px Nunito Sans; color: #4D4D4D; opacity: 1;">
                A senha temporária do funcionário será os 8 primeiros dígitos do CPF.
            </span>
    </div>
    <p-footer>
        <button class="btn btn-secondary" (click)="onCloseResetPwdModal()">Fechar</button>
        <button class="btn btn-primary" (click)="resetEmployeePassword()">Redefinir</button>
    </p-footer>
</p-dialog>

<a routerLink="/employee/list" class="btn btn-primary btn-sm mb-4 mt-2">
    <span class="fa fa-chevron-left mr-2" aria-hidden="true"></span>Voltar
</a>
<div>

    <div class="card">
        <div class="configuration-title">
            <h3>Configurações</h3>
        </div>

        <div class="configuration-data">
            <div class="configuration-data-picture">
                <img
                    [src]="employee?.picture"
                    onerror="this.src='assets/icon/Avatar/user.png'"
                    alt="perfil"
                    class="rounded-circle d-block avatar-perfil img-thumbnail">
            </div>

            <div class="configuration-data-name">
                <span>{{ employee?.name }}</span>
            </div>

            <span [ngClass]="contract?.status === 'ACTIVE' ? 'badge badge-green': 'badge badge-red'">
            {{
                (contract?.status === 'ACTIVE') ? 'Ativo' :
                    (contract?.status === 'INACTIVE') ? 'Inativo' : contract?.status
                }}
          </span>
        </div>

        <div class="card-body">
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Dados Pessoais</h3>
                            <span>E-mail, CPF, Endereço, CEP, Telefone</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="body">
                    <div class="update-form">
                        <app-editing-personal-data [inputEmployee]="employee!" (update)="onUpdate($event)"
                                                   (cancelUpdate)="onCancelUpdatePersonalData($event)"></app-editing-personal-data>
                    </div>
                </ng-template>
            </p-panel>

            <div class="divider"></div>

            <!-- Bank data -->
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"/>
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Dados Bancários</h3>
                            <span>Banco, Conta, Pix e outros</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body">
                    <div class="update-form">
                        <app-editing-personal-bankdata [inputEmployee]="employee!"
                                                       (cancelUpdate)="onCancelUpdatePersonalBankdata($event)"></app-editing-personal-bankdata>
                    </div>
                </ng-template>
            </p-panel>

            <div class="divider"></div>

            <!-- Workday -->
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Jornada de Trabalho</h3>
                            <span>Cargo, período, dias de trabalho</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body">
                    <div class="update-form">
                        <app-employee-configuration [contract]="contract!" [employee]="employee!" [company]="company!"
                                                    (cancelUpdate)="onCancelConfigureEmployee($event)"></app-employee-configuration>
                    </div>
                </ng-template>
            </p-panel>

            <div class="divider"></div>

            <!-- Justifications -->
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Justificativas de Falta</h3>
                            <span>Documentos que comprovam a falta ao trabalho</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body">
                    <div class="body-row mt-0">
                        <app-employee-justifications [contract]="contract!"></app-employee-justifications>
                    </div>
                </ng-template>
            </p-panel>

            <!-- Vacation -->

            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up" *ngIf="contract?.status === 'ACTIVE'">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Férias</h3>
                            <span>Data de início e fim das férias</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body">
                    <div class="body-row mt-0">
                        <app-employee-vacations [contract]="contract!"></app-employee-vacations>
                    </div>
                </ng-template>
            </p-panel>

            <!-- Employee Leave -->
            <p-panel [toggleable]="true" [collapsed]="true" expandIcon="pi pi-chevron-down"
                     collapseIcon="pi pi-chevron-up" *ngIf="contract?.status === 'ACTIVE'">
                <ng-template pTemplate="header">
                    <div class="panel-header">
                        <div class="panel-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                            </svg>
                        </div>
                        <div class="panel-header-title">
                            <h3>Afastamento</h3>
                            <span>Motivo, data de início e fim do afastamento</span>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="body">
                    <div class="body-row mt-0">
                        <app-employee-leave [contract]="contract!" [employee]="employee!"></app-employee-leave>
                    </div>
                </ng-template>
            </p-panel>
        </div>
        <button type="button" class="btn btn-sm btn-link ml-3 btn-change-pwd" (click)="openResetEmployeePasswordModal()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z" />
            </svg>
            Redefinir Senha
        </button>
    </div>
</div>
