<div class="w-100">
    <p class="ml-1 mr-1">Selecione o período que você deseja que esse formulário fique ativo.</p>

    <div>
        <form [formGroup]="formActive" class="form-row d-flex justify-content-center w-100">
            <div class="row w-100">
                <div class="col">
                    <p class="searchbar-label">Data Inicial*</p>
                    <p-calendar
                        #startDate
                        [showIcon]="false"
                        placeholder="Selecione uma data"
                        dateFormat="dd/mm/yy"
                        appendTo="body"
                        formControlName="startDate"
                        inputStyleClass="form-control">
                    </p-calendar>
                </div>
            </div>

            <div class="row w-100">
                <div class="col">
                    <p class="searchbar-label">Data Final*</p>
                    <p-calendar
                        [showIcon]="false"
                        placeholder="Selecione uma data"
                        [minDate]="startDate.value"
                        dateFormat="dd/mm/yy"
                        appendTo="body"
                        formControlName="endDate"
                        inputStyleClass="form-control">
                    </p-calendar>
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-end pb-5 pt-3">
            <button type="button" class="btn btn-link mr-2" (click)="onClose()">Cancelar</button>
            <button type="button" class="btn btn-primary btn-default" (click)="onSave()" [disabled]="formActive.invalid">
                Reativar
            </button>
        </div>
    </div>
</div>

