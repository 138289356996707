<div class="sidebar-card mb-4">
    <div class="sidebar card shadow">
        <div class="sidebar-card-body card-body">
            <ul class="navbar-nav sidebar sidebar-dark accordion sidebar-toggled" id="accordionSidebar">
                <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/">
                    <div class="sidebar-brand-icon">
                        <img class="img-logo-sm" src="./assets/img/logo-blue-icon.png"/>
                    </div>
                    <div class="sidebar-brand-text mx-3">
                        <img class="img-logo" src="{{logo ? logo : defaultLogo}}?{{dateNow}}"/>
                    </div>
                </a>
                <hr class="sidebar-divider my-0">
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/dashboard">
                        <img src="assets/icon/Home/Home.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Home</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/register-time" [ngClass]="{'disabled-link': !enableEditing}">
                        <img src="assets/icon/Clock/clock.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Bater Ponto</span>
                    </a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" [routerLink]="['/employee', 'list']"  [ngClass]="{'disabled-link': !enableEditing}">
                        <img src="assets/icon/User/user-group.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{exact: true}"/>
                        <span>Funcionários</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/comp-time">
                        <img src="assets/icon/Calculator/calculator.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Banco de Horas</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/employee-vacations-table">
                        <img src="assets/icon/Sun/sun.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Férias</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/employee-leave-table">
                        <img src="assets/icon/ArrowLeftOnRectangle/arrow-left-on-rectangle.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Afastamento</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/employee-justification">
                        <img src="assets/icon/Document/document.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Justificativas</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/history">
                        <img src="assets/icon/Clock/clock.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Histórico</span></a>
                </li>
                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/company">
                        <img src="assets/icon/Office/office.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Empresa</span></a>
                </li>

                <li class="nav-item" routerLinkActive="active" *ngIf="showlink" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link" routerLink="/forms-list">
                        <img src="assets/icon/ClipboardDocumentList/clipboard-document-list.svg" class="sidebar-icon" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }"/>
                        <span>Formulários</span></a>
                </li>

                <div class="sidebar-card" *ngIf="isFreePlan">
                    <img class="sidebar-card-illustration mb-2" src="./assets/img/undraw_rocket.svg" alt="">
                    <p class="text-center mb-2" style="color: #5056cc;">
                        <strong>Alphatime PRO</strong> faça uma assinatura e gerencie o tempo e
                        recursos da
                        sua
                        empresa!
                    </p>
                    <a class="btn btn-success btn-sm" routerLink="/signature">Assinar</a>
                </div>

                <div class="text-center d-none d-md-inline mt-4">
                    <button class="btn-toggle rounded-circle border-0 shadow" id="sidebarToggle">
                        <i class="fa fa-chevron-left"></i>
                    </button>
                </div>
            </ul>
        </div>
    </div>
</div>
