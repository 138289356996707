import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';
import {FormGeneratorService} from '../../services/form-generator.service';

@Component({
  selector: 'app-form-activate',
  templateUrl: './form-activate.component.html',
  styleUrls: ['./form-activate.component.scss']
})
export class FormActivateComponent implements OnInit {

  public formActive: FormGroup;

  constructor(
      public config: DynamicDialogConfig,
      private formBuilder: FormBuilder,
      private ref: DynamicDialogRef,
      private formGeneratorService: FormGeneratorService,
  ) {
      this.formActive = this.formBuilder.group({
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
      });
  }

  public async onSave(): Promise<void> {
      const { startDate, endDate } = this.formActive.value;
      const {id} = this.config.data;
      const data = {
          enabled: true,
          startDate: moment(startDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      };
      await this.formGeneratorService.update(id, data).toPromise();
      this.onClose();
  }

  ngOnInit(): void {
  }

  onClose(): void {
      this.ref.close();
  }

}
