import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormActivateComponent} from '../form-activate/form-activate.component';
import {FormGeneratorService} from '../../services/form-generator.service';
import {Survey} from '../../model/Survey';
import {Employee} from '../../model/employee';
import {AppStorageService} from '../../services/app-storage.service';
import {CompanyService} from '../../services/company.service';
import {forkJoin, map} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-forms-list',
  templateUrl: './forms-list.component.html',
  styleUrls: ['./forms-list.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class FormsListComponent implements OnInit {
    @ViewChild('dt') table?: Table;

    public formGroup: FormGroup;
    public ref: DynamicDialogRef = new DynamicDialogRef();
    public survey: Survey | undefined;
    public formData: any;
    private employee: Employee | undefined;

    constructor(
        private appStorageService: AppStorageService,
        private companyService: CompanyService,
        private fornBuilder: FormBuilder,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        private formGeneratorService: FormGeneratorService,
        private messageService: MessageService,
    ) {
        this.formGroup = this.fornBuilder.group({
            name: [''],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
        });
    }

    async ngOnInit(): Promise<void> {
        await this.fetchData();
    }

    async fetchData(params?: { name: string; startDate: string; endDate: string }): Promise<void> {
        this.employee = await this.appStorageService.getEmployee();

        forkJoin([
            this.companyService.get(this.employee.companyId as string),
            this.formGeneratorService.findAll(this.employee.companyId as string, params)
        ]).pipe(
            map(([companyResponse, formGeneratorResponse]: any) => {
                const company = companyResponse.data;
                const formGenerators = formGeneratorResponse.data;

                formGenerators.map((formGenerator: any) => {
                    formGenerator.company = company;
                });

                return formGenerators;
            })
        ).subscribe(
            (formGeneratorsWithCompany) => {
                this.formData = formGeneratorsWithCompany;
            },
            (error) => {
                console.error('Error:', error);
            }
        );

    }

    public acceptFunction: () => void = () => {};
    public rejectFunction: () => void = () => {};

    public async onDeleteForm(id: string): Promise<void> {
        this.acceptFunction = async () => {
            await this.formGeneratorService.delete(id).toPromise();
            this.messageService.add({
                severity: 'success',
                summary: 'Formulário deletado!',
            });
            this.closeDialog();
            await this.fetchData();
        };

        this.rejectFunction = async () => {
            this.messageService.add({
                severity: 'warn',
                summary: 'Não confirmado',
            });
            this.closeDialog();
        };

        this.confirmationService.confirm({
            message: 'Você tem certeza que deseja excluir o formulário?',
            header: 'Excluir Formulário',
            accept: () => this.acceptFunction(),
            reject: () =>  this.rejectFunction(),
        });
    }

    public async onDeactivateForm(id: string): Promise<void> {
        this.acceptFunction = async () => {
            const startDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            const endDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            const data = {
                enabled: false,
                startDate,
                endDate
            };

            await this.formGeneratorService.update(id, data).toPromise();
            this.messageService.add({
                severity: 'success',
                summary: 'Formulário desativado!',
            });
            this.closeDialog();
            await this.fetchData();
        };

        this.rejectFunction = async () => {
            this.messageService.add({
                severity: 'warn',
                summary: 'Não desativado!',
            });
            this.closeDialog();
        };

        this.confirmationService.confirm({
            key: 'deactivate',
            message: 'Você tem certeza que deseja desativar o formulário?',
            header: 'Desativar Formulário',
            accept: () => {},
            reject: () => this.rejectFunction(),
        });
    }

    public onActivateForm(id: string): void {
        this.ref = this.dialogService.open(FormActivateComponent, {
            header: 'Reativar Formulário',
            width: '553px',
            contentStyle: {'max-height': '500px', overflow: 'auto'},
            baseZIndex: 10000,
            dismissableMask: true,
            data: {id}
        });

        this.ref.onClose.subscribe(async () => {
            await this.fetchData();
        });
    }

    public async onSearchForm(): Promise<void> {
        const data = {
            name: this.formGroup.get('name')?.value,
            startDate: moment(this.formGroup.get('startDate')?.value || '').isValid()
                ? moment(this.formGroup.get('startDate')?.value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                : '',
            endDate: moment(this.formGroup.get('endDate')?.value || '').isValid()
                ? moment(this.formGroup.get('endDate')?.value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                : '',
        };

        await this.fetchData(data);
    }

    public async onClearFilter(): Promise<void> {
        this.formGroup.reset({
            name: '',
            startDate: '',
            endDate: '',
        });
        await this.fetchData();
    }

    closeDialog(): void {
        this.confirmationService.close();
    }

}
