<p-toast [style]="{marginTop: '80px'}"></p-toast>
<p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000" key="confirm-delete"
                 acceptButtonStyleClass="p-button-rounded p-button-danger"
                 rejectButtonStyleClass="p-button-text"
                 acceptLabel="Sim"
                 rejectLabel="Não"
                 acceptIcon="none"
                 rejectIcon="none">
</p-confirmDialog>
<p-dialog header="Imagem da logo" [(visible)]="openUploadDialog" [style]="{width: '350px'}" [baseZIndex]="10000">
    <div class="d-flex justify-content-center">
        <div class="logo">
            <div class="image">
                <img [src]="companyLogoTemp ? companyLogoTemp : logo" alt="">
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="text-center">
            <label class="upload-logo-label">
                Trocar
                <input type="file" style="display: none; cursor: pointer;" accept="image/*"
                       (change)="onImageUpload($event)">
            </label>
            <p-button label="Confirmar" styleClass="p-button-rounded p-button-primary"
                      (onClick)="onConfirmUpload()"></p-button>
        </div>
    </ng-template>
</p-dialog>
<div>
    <p-tabView styleClass="tabview-custom" (onChange)="handleChange($event)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <span>Informações</span>
            </ng-template>

            <div class="tabview-body mb-3">
                <div class="d-flex justify-content-center text-muted align-items-center p-3" *ngIf="!company">
                    <span class="spinner-grow spinner-grow-sm m-2" role="status" aria-hidden="true"></span>
                    Aguarde estamos carregando os dados...
                </div>
                <div *ngIf="company">
                    <div *ngIf="!editingCompany">
                        <div class="tabview-body-title d-flex justify-content-between align-items-center">
                            <!-- UPLOAD COMPANY LOGO -->
                            <div class="d-flex align-items-center">
                                <div (click)="onOpenUploadDialog(company.id!, company.logo!)" style="cursor: pointer;">
                                    <p-avatar
                                        image="{{company.logo ? company.logo : logo}}?{{dateNow}}"
                                        onerror="this.src='assets/icon/Avatar/user.png'"
                                        styleClass="mr-2"
                                        size="xlarge"
                                        [style]="{
                                        'outline': 'thin solid #D2D2D2',
                                        'object-fit': 'contain'
                                    }"
                                        shape="circle">
                                    </p-avatar>
                                    <p-avatar
                                        image="assets/img/camera-photo.png"
                                        styleClass="mr-2"
                                        [style]="{
                                        'background-color': '#FFFFFF',
                                        'color': '#5055CB',
                                        'margin-left': '-26px',
                                        'position': 'relative',
                                        'top': '5px',
                                        'width': '23px',
                                        'height': '23px'
                                    }"
                                        shape="circle">
                                    </p-avatar>
                                </div>

                                <h3 class="m-0" pTooltip="{{company.name}}"
                                    tooltipPosition="bottom">{{ company.name! | textTruncate:30 }}
                                    <span class="badge-plan-info badge badge-blue" *ngIf="company.type === 'ECONOMIC_GROUP'">
<!--                                        <a routerLink="/signature">{{ isFreePlan ? 'Plano Gratis' : currentPlan}}</a>-->
                                        <span>{{ isFreePlan ? 'Plano Gratis' : currentPlan}}</span>
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0 plan-icon" fill="none"-->
<!--                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round"-->
<!--                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>-->
<!--                                        </svg>-->
                                      </span>
                                    <span
                                        *ngIf="company.type === 'ECONOMIC_GROUP' && isFreePlan"
                                        [ngClass]="{
                                            'badge-blue': this.counterDays > 7,
                                            'badge-orange': this.counterDays > 2 && this.counterDays <= 7 ,
                                            'badge-red': this.counterDays < 2
                                          }"
                                          class="badge-plan-info badge">
                                        {{this.counterDays}} dias restantes
                                    </span>
                                </h3>
                            </div>
                            <div>
                                <button type="button" class="btn btn-primary btn-sm mr-2" (click)="onEditingCompany()"
                                        [disabled]="!companyData">
                                    <div class="d-flex justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                        </svg>
                                        <span class="ml-2">Editar</span>
                                    </div>
                                </button>

                                <button
                                    type="button" class="btn btn-outline-primary btn-sm"
                                    *ngIf="company.type === 'ECONOMIC_GROUP'"
                                    (click)="onCreateNewCompany()"
                                    [disabled]="!companyData">
                                    <div class="d-flex justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4"/>
                                        </svg>
                                        <span class="ml-2">Adicionar Empresa</span>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div>
                            <app-company-details [company]="company"></app-company-details>
                        </div>
                    </div>
                </div>

                <div *ngIf="editingCompany">
                    <app-company-update [companyData]="company"
                                        [companyPrincipalData]="company"
                                        (cancelUpdate)="onCancelCompany($event)"></app-company-update>
                </div>

                <!-- -------------------------- -->
                <!-- ECONOMIC_GROUP branch list -->
                <!-- -------------------------- -->
                <div *ngFor="let branch of branchesList" class="panel-branch">
                    <div *ngFor="let branchItems of branch.Items; let i = index;">
                        <div *ngIf="branchItems.status !== 'INACTIVE'">
                            <p-panel [toggleable]="true" [expandIcon]="'pi pi-angle-down'" [collapsed]="true"
                                     [collapseIcon]="'pi pi-angle-up'">
                                <ng-template pTemplate="header">
                                    <p-avatar
                                        image="{{branchItems.logo ? branchItems.logo : logo}}?{{dateNow}}"
                                        onerror="this.src='assets/icon/Avatar/user.png'"
                                        styleClass="mr-2"
                                        size="large"
                                        [style]="{
                                        'outline': 'thin solid #D2D2D2',
                                        'object-fit': 'contain'
                                    }"
                                        shape="circle">
                                    </p-avatar>
                                    <span class="p-panel-title">{{branchItems.name | textTruncate:60}}</span>
                                </ng-template>
                                <ng-template pTemplate="icons">
                                    <button type="button" #editBranch class="btn btn-link btn-icon-primary p-0 mb-0"
                                            (click)="newBranch = i+branchItems.id">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                        </svg>
                                    </button>
                                    <!-- DELETE BRANCH -->
                                    <button type="button" #editBranch class="btn btn-link btn-icon-danger p-0 mb-0"
                                            (click)="onDelete(branchItems.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                        </svg>
                                    </button>
                                </ng-template>
                                <div [attr.id]="'edit'+ i+branchItems.id" *ngIf="newBranch !== i+branchItems.id">
                                    <app-company-details [company]="branchItems"></app-company-details>
                                </div>
                                <div [attr.id]="'edit'+ i+branchItems.id" *ngIf="newBranch === i+branchItems.id">
                                    <app-company-update [companyData]="branchItems"
                                                        (cancelUpdate)="onCancelCompany($event)"></app-company-update>
                                </div>
                            </p-panel>
                        </div>
                    </div>
                </div>

                <!-- ----------------------------- -->
                <!-- Add new ECONOMIC_GROUP Branch -->
                <!-- ----------------------------- -->
                <div *ngIf="company">
                    <div class="row body-row" *ngIf="!isCreateNewBranch && !editingCompany">
                        <button type="button" class="btn btn-light" (click)="onCreateNewBranch()"
                                [disabled]="!companyData"
                                style="color: #5055CB; border: 1px solid #5055CB; width: 100%;">
                            <div class="d-flex justify-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor" stroke-width="2" style="height: 25px;">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4"/>
                                </svg>
                                <span class="ml-2">Adicionar Filial</span>
                            </div>
                        </button>
                    </div>
                </div>

                <div *ngIf="isCreateNewBranch && !editingCompanyBranch">
                    <app-company-new
                        (cancelUpdate)="onCancelCompanyBranch($event)"
                        [companyPrincipalData]="company"
                        [isBranch]=true>
                    </app-company-new>
                </div>
            </div>

            <!-- -------------------- -->
            <!-- Add new Company Form -->
            <!-- -------------------- -->
            <div *ngIf="isCreateNewCompany && !editingCompany">
                <div class="tabview-body mb-3">
                    <app-company-new
                        (cancelUpdate)="onCancelCreateCompany($event)"
                        [companyPrincipalData]="company"
                        [isBranch]=false>
                    </app-company-new>
                </div>
            </div>

            <!-- -------------- -->
            <!-- Companies List -->
            <!-- -------------- -->
            <div *ngFor="let ac of companyList">
                <div *ngFor="let companyItems of ac.Items; let i = index;">
                    <div *ngIf="companyItems.status !== 'INACTIVE'">
                        <div class="tabview-body mb-3">
                            <div class="tabview-body-title d-flex justify-content-between align-items-center"
                                 *ngIf="newBranch === -1">
                                <!-- UPLOAD COMPANY LOGO -->
                                <div class="d-flex align-items-center">
                                    <div (click)="onOpenUploadDialog(companyItems.id!, companyItems.logo)"
                                         style="cursor: pointer;">
                                        <p-avatar
                                            image="{{companyItems.logo ? companyItems.logo : logo}}?{{dateNow}}"
                                            onerror="this.src='assets/icon/Avatar/user.png'"
                                            styleClass="mr-2"
                                            size="xlarge"
                                            [style]="{
                                            'outline': 'thin solid #D2D2D2',
                                            'object-fit': 'contain'
                                        }"
                                            shape="circle">
                                        </p-avatar>
                                        <p-avatar
                                            image="assets/img/camera-photo.png"
                                            styleClass="mr-2"
                                            [style]="{
                                            'background-color': '#FFFFFF',
                                            'color': '#5055CB',
                                            'margin-left': '-26px',
                                            'position': 'relative',
                                            'top': '5px',
                                            'width': '23px',
                                            'height': '23px'
                                        }"
                                            shape="circle">
                                        </p-avatar>
                                    </div>

                                    <h3 class="m-0" pTooltip="{{companyItems.name}}"
                                        tooltipPosition="bottom">{{ companyItems.name | textTruncate:30 }}</h3>
                                </div>
                                <div>
                                    <!-- DELETE COMPANY -->
                                    <button type="button" #editBranch class="btn btn-link btn-icon-danger p-0 mb-0"
                                            (click)="onDelete(companyItems.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.6">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                        </svg>
                                    </button>
                                    <button type="button" class="btn btn-primary btn-sm mr-2" [disabled]="!companyItems"
                                            (click)="newBranch = i+companyItems.id">
                                        <div class="d-flex justify-content-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                            </svg>
                                            <span class="ml-2">Editar</span>
                                        </div>
                                    </button>
                                </div>

                            </div>
                            <div>
                                <app-company-details [company]="companyItems"
                                                     *ngIf="newBranch !== i+companyItems.id"></app-company-details>
                            </div>
                            <div [attr.id]="'edit'+ i+companyItems.id" *ngIf="newBranch === i+companyItems.id">
                                <app-company-update [companyData]="companyItems"
                                                    [companyPrincipalData]="company"
                                                    (cancelUpdate)="onCancelCompany($event)"></app-company-update>
                            </div>

                            <!-- -------------------------- -->
                            <!-- Company branch list -->
                            <!-- -------------------------- -->
                            <div *ngFor="let companiesBranch of economicGroupBranchList" class="panel-branch">
                                <div *ngFor="let companiesBranchItems of companiesBranch.Items; let i = index;">
                                    <div *ngIf="companiesBranchItems.status !== 'INACTIVE'">
                                        <div *ngIf="companiesBranchItems.companyId === companyItems.id">
                                            <p-panel [header]="companiesBranchItems.name | textTruncate:60"
                                                     [toggleable]="true" [expandIcon]="'pi pi-angle-down'"
                                                     [collapsed]="true" [collapseIcon]="'pi pi-angle-up'">
                                                <ng-template pTemplate="header">
                                                    <p-avatar
                                                        image="{{companiesBranchItems.logo ? companiesBranchItems.logo : logo}}?{{dateNow}}"
                                                        onerror="this.src='assets/icon/Avatar/user.png'"
                                                        styleClass="mr-2"
                                                        size="large"
                                                        [style]="{
                                                        'outline': 'thin solid #D2D2D2',
                                                        'object-fit': 'contain'
                                                    }"
                                                        shape="circle">
                                                    </p-avatar>
                                                    <!-- <span class="p-panel-title">{{companiesBranchItems.name | textTruncate:60}}</span> -->
                                                </ng-template>
                                                <ng-template pTemplate="icons">
                                                    <button type="button" #editBranch
                                                            class="btn btn-link btn-icon-primary p-0 mb-0"
                                                            (click)="newBranch = i+companiesBranchItems.id">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0"
                                                             fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                             stroke-width="1.6">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                                                        </svg>
                                                    </button>
                                                    <!-- DELETE BRANCH -->
                                                    <button type="button" #editBranch
                                                            class="btn btn-link btn-icon-danger p-0 mb-0"
                                                            (click)="onDelete(companiesBranchItems.id)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-0 w-0"
                                                             fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                                             stroke-width="1.6">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                                        </svg>
                                                    </button>
                                                </ng-template>
                                                <div [attr.id]="'edit'+ i+companiesBranchItems.id"
                                                     *ngIf="newBranch !== i+companiesBranchItems.id">
                                                    <app-company-details
                                                        [company]="companiesBranchItems"></app-company-details>
                                                </div>
                                                <div [attr.id]="'edit'+ i+companiesBranchItems.id"
                                                     *ngIf="newBranch === i+companiesBranchItems.id">
                                                    <app-company-update [companyData]="companiesBranchItems"
                                                                        [companyPrincipalData]="company"
                                                                        (cancelUpdate)="onCancelCompany($event)"></app-company-update>
                                                </div>
                                            </p-panel>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Add new Branch -->
                            <div class="row body-row" *ngIf="!isCreateNewBranch && !editingCompany">
                                <button type="button" class="btn btn-light" (click)="newBranch=i"
                                        [disabled]="!companyData"
                                        style="color: #5055CB; border: 1px solid #5055CB; width: 100%;">
                                    <div class="d-flex justify-content-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-0" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"
                                             style="height: 25px;">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4"/>
                                        </svg>
                                        <span class="ml-2">Adicionar Filial</span>
                                    </div>
                                </button>
                            </div>

                            <div [attr.id]="'undoBtn'+i" *ngIf="newBranch === i">

                                <app-company-new
                                    (cancelUpdate)="onCancelCompanyBranch($event)"
                                    [companyPrincipalData]="companyItems"
                                    [isBranch]=true>
                                </app-company-new>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </p-tabPanel>

        <p-tabPanel header="Departamentos" [cache]="false">
            <ng-template pTemplate="header">
                <span>Departamentos</span>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="tabview-body mb-3">
                    <app-department-table [company]="company"></app-department-table>
                </div>
            </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Cargos" *ngIf="company?.type === 'ECONOMIC_GROUP'">
            <ng-template pTemplate="header">
                <span>Cargos</span>
            </ng-template>

            <div class="tabview-body mb-3">
                <app-company-position></app-company-position>
            </div>
        </p-tabPanel>

        <p-tabPanel header="Cargos">
            <ng-template pTemplate="header">
                <span>Jornada de Trabalho</span>
            </ng-template>

            <div class="tabview-body mb-3">
                <div *ngIf="company">
                    <app-working-day *ngIf="showWorkingDayComponent" [companyWorkDay]="company"
                                     (cancelUpdate)="onCancelCompany($event)"></app-working-day>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
