<p-toast></p-toast>
<div>
    <p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000">
        <ng-template pTemplate="footer">
            <button type="button" class="btn btn-sm btn-danger" (click)="acceptFunction()">
                Sim, excluir
            </button>
            <button type="button" class="btn btn-sm btn-default" (click)="rejectFunction()">
                Não
            </button>
        </ng-template>
    </p-confirmDialog>

    <p-confirmDialog [style]="{width: '432px'}" [baseZIndex]="10000" key="deactivate">
        <ng-template pTemplate="footer">
            <button type="button" class="btn btn-sm btn-danger" style="width: 140px" (click)="acceptFunction()">
                Sim, desativar
            </button>
            <button type="button" class="btn btn-sm btn-default" (click)="rejectFunction()">
                Não
            </button>
        </ng-template>
    </p-confirmDialog>

    <div class="mb-4 d-flex">
        <form [formGroup]="formGroup" class="form-row align-items-center w-100">
            <div class="row w-100">
                <div class="col">
                    <p class="searchbar-label">Formulário</p>
                    <span class="p-input-icon-left w-100">
                            <i class="pi pi-search"></i>
                            <input
                                type="text"
                                class="p-inputtext-sm w-100"
                                formControlName="name"
                                pInputText placeholder="Pesquisar por formulários" />
                        </span>
                </div>
                <div class="col">
                    <p class="searchbar-label">Data Inicial</p>
                    <p-calendar
                        #startDate
                        [showIcon]="false"
                        placeholder="Selecione uma data"
                        dateFormat="dd/mm/yy"
                        formControlName="startDate"
                        inputStyleClass="form-control">
                    </p-calendar>
                </div>

                <div class="col">
                    <p class="searchbar-label">Data Final</p>
                    <p-calendar
                        [showIcon]="false"
                        placeholder="Selecione uma data"
                        [minDate]="startDate.value"
                        dateFormat="dd/mm/yy"
                        formControlName="endDate"
                        inputStyleClass="form-control">
                    </p-calendar>
                </div>

                <div class="col">
                    <div class="d-flex">
                        <div class="mr-3">
                            <p class="searchbar-label">&nbsp;</p>
                            <button type="button" class="btn btn-sm btn-link" (click)="onClearFilter()">
                                Limpar filtro
                            </button>
                        </div>

                        <div>
                            <p class="searchbar-label">&nbsp;</p>
                            <button
                                type="submit"
                                class="btn btn-sm btn-primary"
                                (click)="onSearchForm()"
                            >
                                Filtrar
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </form>

        <div class="col-auto d-flex align-items-end mb-2">
            <a class="btn btn-primary" routerLink="/form-creator">
                <span>Criar formulário</span>
            </a>
        </div>
    </div>

    <div class="card shadow">
        <div class="card-body">
            <div class="table-responsive">
                <p-table #dt
                         [value]="formData"
                         dataKey="id"
                         styleClass="p-datatable-employee"
                         [rowHover]="true"
                         [rows]="10"
                         [showCurrentPageReport]="true"
                         [rowsPerPageOptions]="[10,25,50]"
                         [paginator]="true"
                         [filterDelay]="0"
                         sortField="recordDate" [sortOrder]="-1"
                         [globalFilterFields]="['enabled', 'name', 'startDate', 'endDate', 'company']">

                    <ng-template pTemplate="header">
                        <tr *ngIf="formData?.length !== 0">
                            <th pSortableColumn="enabled">Status
                                <p-sortIcon field="enabled"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">Nome
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <th pSortableColumn="startDate">Data Inicial
                                <p-sortIcon field="startDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="endDate">Data Final
                                <p-sortIcon field="endDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="company">Empresa
                                <p-sortIcon field="company"></p-sortIcon>
                            </th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-form let-i>
                        <tr class="p-selectable-row">
                            <td>
                                <span *ngIf="form.enabled === true" [class]="'badge badge-green'">Ativo</span>
                                <span *ngIf="form.enabled === false" [class]="'badge badge-gray'">Inativo</span>
                            </td>
                            <td>{{ form.name }}</td>
                            <td>{{ form.startDate | date:'dd/MM/yy' }}</td>
                            <td>{{ form.endDate | date:'dd/MM/yy' }}</td>
                            <td>{{ form.company.name }}</td>
                            <td>
                                <span class="text-danger cursor-pointer mr-3" pTooltip="Excluir Formulário" tooltipPosition="top" (click)="onDeleteForm(form.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                </span>

                                <span *ngIf="form.enabled" class="text-danger cursor-pointer" pTooltip="Desativar Formulário" tooltipPosition="top" (click)="onDeactivateForm(form.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                                    </svg>
                                </span>

                                <span *ngIf="!(form.enabled)" class="text-success cursor-pointer" pTooltip="Reativar Formulário" tooltipPosition="top" (click)="onActivateForm(form.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
                                    </svg>
                                </span>

                                <span *ngIf="false" class="text-primary" pTooltip="Configurações" tooltipPosition="top">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
